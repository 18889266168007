import React from "react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import img1 from "../../Data/AR 2/COMMON Lo-res/DJI_0433.jpg";

import img2 from "../../Data/AR 2/COMMON Lo-res/DJI_0007.jpg";
import img3 from "../../Data/AR 2/COMMON Lo-res/DSC_0139.jpg";
import img4 from "../../Data/AR 2/COMMON Lo-res/DSC_0086.jpg";
import img5 from "../../Data/AR 2/COMMON Lo-res/DSC_0138.jpg";
import img6 from "../../Data/AR 2/COMMON Lo-res/DSC_0093.jpg";
import img7 from "../../Data/AR 2/COMMON Lo-res/DSC_0101.jpg";

import img8 from "../../Data/AR 2/COMMON Lo-res/DSC_0102.jpg";
import img9 from "../../Data/AR 2/COMMON Lo-res/DSC_0158.jpg";
import img10 from "../../Data/AR 2/COMMON Lo-res/DSC_0129.jpg";
import img11 from "../../Data/AR 2/COMMON Lo-res/DSC_0164.jpg";
import img12 from "../../Data/AR 2/COMMON Lo-res/DSC_0144.jpg";
import img13 from "../../Data/AR 2/COMMON Lo-res/DSC_0175.jpg";

import img14 from "../../Data/AR 2/COMMON Lo-res/DSC_0179.jpg";
import img15 from "../../Data/AR 2/COMMON Lo-res/DSC_0189.jpg";
import img16 from "../../Data/AR 2/COMMON Lo-res/DSC_0191.jpg";

const AR2G1 = ({ setModalAR2G1 }) => {
  return (
    <>
      <MDBRow>
        <MDBCol className="mb-4 mb-lg-0">
          <img
            src={img1}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            style={{ maxHeight: "650px", objectFit: "cover" }}
            onClick={() => setModalAR2G1(img1)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol lg={4} md={12} className="mb-4 mb-lg-0">
          <img
            src={img2}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G1(img2)}
          />

          <img
            src={img3}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G1(img3)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img4}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G1(img4)}
          />

          <img
            src={img5}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G1(img5)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img6}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G1(img6)}
          />

          <img
            src={img7}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G1(img7)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol lg={4} md={12} className="mb-4 mb-lg-0">
          <img
            src={img8}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G1(img8)}
          />

          <img
            src={img9}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G1(img9)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img10}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G1(img10)}
          />

          <img
            src={img11}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G1(img11)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img12}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G1(img12)}
          />

          <img
            src={img13}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G1(img13)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol lg={4} md={12} className="mb-4 mb-lg-0">
          <img
            src={img14}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G1(img14)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img15}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G1(img15)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img16}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G1(img16)}
          />
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default AR2G1;
