import React, { Component } from "react";
import PRING1 from "./PRING1";
import Modal from "../../Components/Modal/Modal.js";

export default class PRIN extends Component {
  state = {
    currentType: true,
    currentGroup: true,
    onModal: false,
    currentModal: null,
  };

  onSetTypePerspective = () => {
    this.setState({ currentType: true });
  };

  onSetTypePlan = () => {
    this.setState({ currentType: false });
  };

  onSetGroupCommon = () => {
    this.setState({ currentGroup: true });
  };

  onSetGroupFamily = () => {
    this.setState({ currentGroup: false });
  };

  handleClose = () => {
    this.setState({ onModal: false });
  };

  handleOpen = (value) => {
    this.setState({ currentModal: value });
    this.setState({ onModal: true });
  };

  render() {
    return (
      <>
        <div>
          <Modal
            isOpen={this.state.onModal}
            onClose={this.handleClose}
            imageData={this.state.currentModal}
          ></Modal>
        </div>
        <div>
          <div className="row p-0 m-0 PRIN-Image" section="1"></div>
          <div className="de-header">PROJECT DETAILS</div>
          <div className="row m-0" style={{ padding: "0px 6%" }}>
            <div
              className="col-md"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                marginBottom: "15px",
              }}
            >
              <div className="de-projectName">PRIN RESIDENCE</div>
              <div className="de-loEN">BANGKOK, THAILAND</div>
              <div className="de-loTH">
                บ้านพักอาศัย 3 ชั้น เขตราษฎบูรณะ กรุงเทพ
              </div>
              <div className="row p-0 m-0">
                <div className="col-4 p-0 m-0 de-work">
                  งานที่<span className="wraporno">ดำเนินการ</span>
                </div>
                <div className="col-8 p-0 m-0 de-work-detail">
                  • งานออกแบบสถาปัตยกรรม
                </div>
              </div>
            </div>
            <div
              className="col-md test"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div className="row p-0 m-0">
                <div className="col-md p-0 m-0 de-san-bold">พื้นที่ใช้สอย</div>
                <div className="col-md p-0 m-0 de-san-reg">850 ตารางเมตร</div>
              </div>
              <div className="row p-0 m-0">
                <div className="col-md p-0 m-0 de-san-bold">
                  มูลค่าก่อสร้างโครงการ
                </div>
                <div className="col-md p-0 m-0 de-san-reg">10 ล้านบาท</div>
              </div>
              <div className="row p-0 m-0">
                <div className="col-md p-0 m-0 de-san-bold">ปีที่แล้วเสร็จ</div>
                <div className="col-md p-0 m-0 de-san-reg">2012</div>
              </div>
            </div>
          </div>
          <div className="row m-0 p-0 mt-5 d-none">
            <div className="d-flex flex-row justify-content-center">
              <div
                className={
                  this.state.currentType ? "de-btn-selected" : "de-btn-unselect"
                }
                onClick={this.onSetTypePerspective}
              >
                PERSPECTIVE
              </div>
              <div
                className={
                  this.state.currentType ? "de-btn-unselect" : "de-btn-selected"
                }
                onClick={this.onSetTypePlan}
              >
                MASTER PLAN
              </div>
            </div>
          </div>

          <div className="d-none">
            <div className="d-flex justify-content-center">
              <div
                className={
                  this.state.currentGroup
                    ? "de-subbtn-selected"
                    : "de-subbtn-unselect"
                }
                onClick={this.onSetGroupCommon}
                style={{ textAlign: "right" }}
              >
                COMMON
              </div>
              <div
                className={
                  this.state.currentGroup
                    ? "de-subbtn-unselect"
                    : "de-subbtn-selected"
                }
                onClick={this.onSetGroupFamily}
                style={{ textAlign: "left" }}
              >
                FAMILY
              </div>
            </div>
          </div>

          <div className="de-gellery mt-5">
            <PRING1 setModalPring={this.handleOpen} />
          </div>

          <div className="row p-0 m-0 section5" section="5">
            <p
              className="text-center"
              style={{ paddingTop: "72px", fontSize: "12px" }}
            >
              copyright ©2022. supported by seven bridges creation
            </p>
          </div>
        </div>
      </>
    );
  }
}
