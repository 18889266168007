import React from "react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import img1 from "../../Data/N11/N11Lo-res/DSC_4071.jpg";

import img2 from "../../Data/N11/N11Lo-res/DSC_4100.jpg";
import img3 from "../../Data/N11/N11Lo-res/DSC_4105.jpg";
import img4 from "../../Data/N11/N11Lo-res/DSC_4132.jpg";
import img5 from "../../Data/N11/N11Lo-res/DSC_4135.jpg";
import img6 from "../../Data/N11/N11Lo-res/DSC_4137.jpg";
import img7 from "../../Data/N11/N11Lo-res/DSC_4139.jpg";

import img8 from "../../Data/N11/N11Lo-res/DSC_4161.jpg";
import img9 from "../../Data/N11/N11Lo-res/DSC_4306.jpg";
import img10 from "../../Data/N11/N11Lo-res/DSC_4339.jpg";
import img11 from "../../Data/N11/N11Lo-res/DSC_4361.jpg";
import img12 from "../../Data/N11/N11Lo-res/DSC_4379.jpg";
import img13 from "../../Data/N11/N11Lo-res/DSC_4383.jpg";

import img14 from "../../Data/N11/N11Lo-res/DSC_4414.jpg";
import img15 from "../../Data/N11/N11Lo-res/DSC_4415.jpg";
import img16 from "../../Data/N11/N11Lo-res/DSC_4418.jpg";
import img17 from "../../Data/N11/N11Lo-res/DSC_4550.jpg";
import img18 from "../../Data/N11/N11Lo-res/DSC_4586.jpg";
import img19 from "../../Data/N11/N11Lo-res/DSC_4599.jpg";

const N11G1 = ({ setModalN11G1 }) => {
  return (
    <>
      <MDBRow>
        <MDBCol className="mb-4 mb-lg-0">
          <img
            src={img1}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            style={{ maxHeight: "650px", objectFit: "cover" }}
            onClick={() => setModalN11G1(img1)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol lg={4} md={12} className="mb-4 mb-lg-0">
          <img
            src={img2}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalN11G1(img2)}
          />

          <img
            src={img3}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalN11G1(img3)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img4}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalN11G1(img4)}
          />

          <img
            src={img5}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalN11G1(img5)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img7}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalN11G1(img7)}
          />

          <img
            src={img6}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalN11G1(img6)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol lg={4} md={12} className="mb-4 mb-lg-0">
          <img
            src={img8}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalN11G1(img8)}
          />

          <img
            src={img9}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalN11G1(img9)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img10}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalN11G1(img10)}
          />

          <img
            src={img11}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalN11G1(img11)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img12}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalN11G1(img12)}
          />

          <img
            src={img13}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalN11G1(img13)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img17}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalN11G1(img17)}
          />
          <img
            src={img16}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalN11G1(img16)}
          />
        </MDBCol>
        <MDBCol lg={4} md={12} className="mb-4 mb-lg-0">
          <img
            src={img14}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalN11G1(img14)}
          />

          <img
            src={img15}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalN11G1(img15)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img18}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalN11G1(img18)}
          />

          <img
            src={img19}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalN11G1(img19)}
          />
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default N11G1;
