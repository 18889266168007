import React from "react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import img1 from "../../Data/VISANU/VISANU Lo-res/DSC_6111.jpg";

import img2 from "../../Data/VISANU/VISANU Lo-res/DSC_5977.jpg";
import img3 from "../../Data/VISANU/VISANU Lo-res/DSC_5997.jpg";
import img4 from "../../Data/VISANU/VISANU Lo-res/DSC_6020.jpg";
import img5 from "../../Data/VISANU/VISANU Lo-res/DSC_6035.jpg";
import img6 from "../../Data/VISANU/VISANU Lo-res/DSC_6174.jpg";
import img7 from "../../Data/VISANU/VISANU Lo-res/DSC_6066.jpg";

const VISANUG1 = ({ setModalVisanu }) => {
  return (
    <>
      <MDBRow>
        <MDBCol className="mb-4 mb-lg-0">
          <img
            src={img1}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            style={{ maxHeight: "650px", objectFit: "cover" }}
            onClick={() => setModalVisanu(img1)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol lg={4} md={12} className="mb-4 mb-lg-0">
          <img
            src={img2}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalVisanu(img2)}
          />

          <img
            src={img3}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalVisanu(img3)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img4}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalVisanu(img4)}
          />

          <img
            src={img5}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalVisanu(img5)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img6}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalVisanu(img6)}
          />

          <img
            src={img7}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalVisanu(img7)}
          />
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default VISANUG1;
