import "./App.css";
import { Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar.js";
import HamurgerMenu from "./Components/Hamburger/HamburgerMenu.js";
import Home from "./Components/Home/Home.js";
import Project from "./Components/Project/Project.js";

import AR2 from "./Project/AR2/AR2";
import N11 from "./Project/N11/N11";
import IDEO from "./Project/IDEO/IDEO";
import PRIN from "./Project/PRIN/PRIN";
import RAYA from "./Project/RAYA/RAYA";
import INOVE from "./Project/INOVE/INOVE";
import DULIYA from "./Project/DULIYA/DULIYA";
import VISANU from "./Project/VISANU/VISANU";
import JAYHOUSE from "./Project/JAYHOUSE/JAYHOUSE";
import SENA from "./Project/SENA/SENA";

function App() {
  return (
    <div className="App-Main">
      <div className="container-menu">
        <Navbar />
        <div className="bur-main">
          <HamurgerMenu />
        </div>
      </div>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Project" element={<Project />} />
          <Route path="/Project/AR2" element={<AR2 />} />
          <Route path="/Project/N11" element={<N11 />} />
          <Route path="/Project/PRIN" element={<PRIN />} />
          <Route path="/Project/IDEO" element={<IDEO />} />
          <Route path="/Project/INOVE" element={<INOVE />} />
          <Route path="/Project/VISANU" element={<VISANU />} />
          <Route path="/Project/DULIYA" element={<DULIYA />} />
          <Route path="/Project/RAYAVADEE" element={<RAYA />} />
          <Route path="/Project/JAYHOUSE" element={<JAYHOUSE />} />
          <Route path="/Project/SENA" element={<SENA />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
