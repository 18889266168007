import React from "react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import img1 from "../../Data/IDEO/IDEO Lo-res/DSCF2343-re.jpg";
import img2 from "../../Data/IDEO/IDEO Lo-res/DSCF2347-re.jpg";
import img3 from "../../Data/IDEO/IDEO Lo-res/DSCF2360.jpg";
import img4 from "../../Data/IDEO/IDEO Lo-res/DSCF4367-re.jpg";
import img5 from "../../Data/IDEO/IDEO Lo-res/DSCF4376.jpg";
import img6 from "../../Data/IDEO/IDEO Lo-res/DSCF2340.jpg";

const IDEOG1 = ({ setModalIdeo }) => {
  return (
    <>
      <MDBRow>
        <MDBCol lg={4} md={12} className="mb-4 mb-lg-0">
          <img
            src={img1}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalIdeo(img1)}
          />

          <img
            src={img2}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalIdeo(img2)}
          />
        </MDBCol>
        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img3}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalIdeo(img3)}
          />

          <img
            src={img4}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalIdeo(img4)}
          />
        </MDBCol>
        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img5}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalIdeo(img5)}
          />

          <img
            src={img6}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalIdeo(img6)}
          />
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default IDEOG1;
