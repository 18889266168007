import React from "react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import img1 from "../../Data/PRIN/PRIN Lo-res/DSC_0044.jpg";
import img2 from "../../Data/PRIN/PRIN Lo-res/DSC_0050_3.jpg";
import img3 from "../../Data/PRIN/PRIN Lo-res/DSC_0111.jpg";
import img4 from "../../Data/PRIN/PRIN Lo-res/DSC_0066.jpg";
import img5 from "../../Data/PRIN/PRIN Lo-res/DSC_0073.jpg";
import img6 from "../../Data/PRIN/PRIN Lo-res/DSC_0075.jpg";
import img7 from "../../Data/PRIN/PRIN Lo-res/DSC_0127.jpg";

import img8 from "../../Data/PRIN/PRIN Lo-res/DSC_0125.jpg";
import img9 from "../../Data/PRIN/PRIN Lo-res/DSC_0088.jpg";
import img10 from "../../Data/PRIN/PRIN Lo-res/DSC_0091.jpg";

const PRING1 = ({ setModalPring }) => {
  return (
    <>
      <MDBRow>
        <MDBCol className="mb-4 mb-lg-0">
          <img
            src={img1}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            style={{ maxHeight: "650px", objectFit: "cover" }}
            onClick={() => setModalPring(img1)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol lg={4} md={12} className="mb-4 mb-lg-0">
          <img
            src={img2}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalPring(img2)}
          />

          <img
            src={img3}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalPring(img3)}
          />
        </MDBCol>
        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img4}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalPring(img4)}
          />

          <img
            src={img5}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalPring(img5)}
          />
        </MDBCol>
        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img6}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalPring(img6)}
          />

          <img
            src={img7}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalPring(img7)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol lg={4} md={12} className="mb-4 mb-lg-0">
          <img
            src={img8}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalPring(img8)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img9}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalPring(img9)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img10}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalPring(img10)}
          />
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default PRING1;
