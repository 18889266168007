import React, { useRef, useState } from "react";
import "./Home.css";
import contractLogo from "../../Data/ContractLogo.png";
import { Link } from "react-router-dom";
import * as Scroll from "react-scroll";
import Slider from "../Slider/Slider";
import AboutLogo from "../../Data/Logo-01.png";
import QRCode from "../../Data/HOME/qr.png";
import fb from "../../Data/HOME/fb-8.png";
import ig from "../../Data/HOME/ig-8.png";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

export default function Home() {
  const Element = Scroll.Element;
  const captchaRef = useRef(null);
  const [phone, setPhone] = useState("");
  const [line, setLine] = useState("");

  function onTypePhone(e) {
    setPhone(e.target.value);
  }

  function onTypeLine(e) {
    setLine(e.target.value);
  }

  function sendEmail(e) {
    e.preventDefault();
    if (phone === "" && line === "") {
      alert("กรุณากรอกข้อมูลการติดต่ออย่างน้อยหนึ่งช่องทาง");
      return;
    }

    const token = captchaRef.current.getValue();
    captchaRef.current.reset();

    if (token === undefined || token === "" || token === null) {
      alert("Please accept ReCaptcha");
      return;
    }

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_EMAIL_PUBLIC_KEY
      )
      .then(
        (result) => {
          alert(
            "We have received your information and will contact you as soon as possible."
          );
          window.location.reload();
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  return (
    <div className="container-fluid text-center p-0 m-0">
      <div className="row p-0 m-0 section1" section="1">
        <Slider />
      </div>
      <div className="row p-0 m-0 section2" section="2">
        <div className="col-md p-0 m-0 section2_1"></div>
        <Element name="about" className="col-md py-5 m-0 section2_2">
          <div>
            <div
              className="m-0 pb-0"
              style={{
                fontSize: "29px",
                fontFamily: "PoppinsSemiBold",
                letterSpacing: "2px",
              }}
            >
              ABOUT
            </div>
            <div className="m-0 pt-0">
              <img
                src={AboutLogo}
                alt=""
                style={{ width: "266px", marginTop: "10px" }}
              />
              {/* <span
                style={{
                  fontSize: "29px",
                  fontFamily: "PoppinsLight",
                  letterSpacing: "2px",
                }}
              >
                SEVEN{"\u00A0"}
              </span>
              <span
                style={{
                  fontSize: "29px",
                  fontFamily: "PoppinsSemiBold",
                  letterSpacing: "2px",
                }}
              >
                BRIDGES
              </span> */}
            </div>
          </div>
          <div className="mt-3" style={{ fontFamily: "PlexSansRegular" }}>
            <div>
              ก่อตั้งขึ้นเมื่อปี 2001 โดยคุณยุทธพงศ์ ทองสีนาค และคุณเสริมศักดิ์
              ตันติสิริเศรณี
              ด้วยแนวคิดที่อยากทำงานสถาปัตยกรรมในรูปแบบที่ตอบสนองความต้องการของเจ้าของอาคารให้ได้มากที่สุด
              ใส่ใจลงลึกในรายละเอียดตั้งแต่งานออกแบบ งานเขียนแบบ
              ตลอดจนถึงขั้นตอนงานก่อสร้าง
            </div>
            <div>
              ประสบการณ์งานออกแบบสถาปัตยกรรมสอนให้เราได้เรียนรู้ในเรื่องของ
              FUNCTION การใช้งาน , ความสัมพันธ์ของ SPACE และ PROPORTION ,
              การเลือกใช้วัสดุที่คงทน ดูแลรักษาง่าย มีอายุการใช้งานที่ยาวนาน
            </div>
            <div>
              ประสบการณ์งานออกแบบตกแต่งภายในสอนให้เราได้เข้าใจลงลึกใน DETAIL
              ที่สัมพันธ์กับงานสถาปัตยกรรม , SCALE และการสร้างจุดเด่น
              สิ่งดึงดูดความสนใจให้กับ SPACE นั้นๆ ,
              หลักการเลือกใช้วัสดุที่แตกต่างออกไปจากงานสถาปัตยกรรม
            </div>
            <div>
              ประสบการณ์งานก่อสร้างจริงเปรียบเสมือน WORKSHOP
              ภาคสนามที่ช่วยสร้างความเข้าใจลำดับขั้นตอนในงานก่อสร้างที่ควรจะเป็น
              ตลอดจนได้ทดลองวิธีการและเทคนิคใหม่ๆเพื่อให้สามารถสรรสร้างงานสถาปัตยกรรมและงานตกแต่งภายในในรูปแบบที่เราต้องการได้
            </div>
            <div>
              จากผลลัพธ์ของประสบการณ์ที่มีทั้ง 3 ด้าน เรานำมาปรับปรุงวิธีคิด
              วิธีการออกแบบ วิธีการทำงาน
              เพื่อให้สอดคล้องประสานกันได้อย่างมีประสิทธิภาพสูงสุด
              ก่อสร้างได้ง่ายไม่ซับซ้อนซึ่งก็จะส่งผลให้ดูแลซ่อมแซมสามารถทำได้โดยง่ายเช่นกัน
            </div>
          </div>
        </Element>
      </div>
      <div className="row p-0 m-0 section3" section="3">
        <Link className="col p-0 m-0 image1" to="/Project">
          <div>
            <span>P</span>
            <span>R</span>
            <span>O</span>
            <span>J</span>
            <span>E</span>
            <span>C</span>
            <span>T</span>
            <span>S</span>
          </div>
          <div>
            <span>MORE</span>
            <span>></span>
          </div>
        </Link>
        <Link className="col p-0 m-0 image2" to="/Project/AR2">
          <div>AR2 RESIDENCE</div>
        </Link>
        <Link className="col p-0 m-0 image3" to="/Project/N11">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>N11 RESIDENCE</span>
            {/* <span style={{ fontFamily: "PoppinsLight", textAlign: "left" }}>
              SAMUT PRAKAN, THAILAND
            </span>
            <span style={{ fontFamily: "PoppinsLight" }}>2020</span> */}
          </div>
        </Link>
        <Link className="col p-0 m-0 image4" to="/Project/Visanu">
          <div>VISANU RESIDENCE</div>
        </Link>
        <Link className="col p-0 m-0 image5" to="/Project/Duliya">
          <div>DULIYA RESIDENCE</div>
        </Link>
      </div>
      <Element
        name="contractEmail"
        className="row p-0 m-0 section6"
        section="6"
      >
        <div className="row section6_1">
          <div className="col-lg-6 section6_1_1">
            <div
              className="paddingLeftTen"
              style={{
                fontFamily: "IBMPlexSansThaiBold",
                fontSize: "55px",
                textAlign: "left",
                fontWeight: 600,
                color: "white",
                lineHeight: 1.3,
                marginBottom: "44px",
              }}
            >
              ยินดีให้คำปรึกษาเรื่องบ้าน
              <br />
              โดยไม่มีค่าใช้จ่าย
            </div>
            <div
              className="paddingLeftTen"
              style={{
                fontFamily: "IBMPlexSansThai",
                fontSize: "27px",
                textAlign: "left",
                color: "white",
              }}
            >
              ติดต่อรับคำปรึกษาได้ทางช่องทางต่อไปนี้
            </div>
            <div
              className="paddingLeftTen"
              style={{
                fontFamily: "PoppinsMedium",
                fontSize: "23px",
                textAlign: "left",
                color: "white",
                marginBottom: "44px",
              }}
            >
              +66 81 839 5066
            </div>
            <div
              className="paddingLeftTen"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "14px",
              }}
            >
              <img
                src={QRCode}
                alt="QR COde"
                style={{ maxWidth: "130px", width: "80vmin" }}
              />
            </div>
            <div
              className="paddingLeftTen"
              style={{
                fontFamily: "PoppinsSemiBold",
                fontSize: "27px",
                textAlign: "left",
                color: "white",
              }}
            >
              Line QR code
            </div>
          </div>
          <div className="col-lg-6 section6_1_2">
            <div className="section6_1_2_1">
              <div
                style={{
                  fontFamily: "IBMPlexSansThai",
                  fontSize: "27px",
                  textAlign: "center",
                  color: "white",
                }}
              >
                กรอกรายละเอียดด้านล่าง
                <br />
                เพื่อให้ทางเราติดต่อกลับไป
              </div>
              <div
                style={{
                  width: "55%",
                  marginTop: "3%",
                  paddingTop: "3%",
                  borderTop: "solid 1px white",
                }}
              >
                <form onSubmit={sendEmail}>
                  <div
                    className="form-group"
                    style={{ textAlign: "left", marginBottom: "5%" }}
                  >
                    <label htmlFor="inputName" style={{ color: "white" }}>
                      *NAME
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputName"
                      name="from_name"
                      aria-describedby="Name Help"
                      placeholder=""
                      required
                    ></input>
                  </div>
                  <div
                    style={{
                      fontFamily: "IBMPlexSansThai",
                      fontSize: "17px",
                      textAlign: "center",
                      color: "white",
                      marginBottom: "3%",
                    }}
                  >
                    เลือกช่องทางที่สะดวกให้ติดต่อกลับ
                  </div>
                  <div
                    className="form-group"
                    style={{ textAlign: "left", marginBottom: "5%" }}
                  >
                    <label htmlFor="inputTel" style={{ color: "white" }}>
                      PHONE
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      id="inputTel"
                      name="from_tel"
                      aria-describedby="Telephone Number Help"
                      placeholder=""
                      onChange={onTypePhone}
                      onClick={onTypePhone}
                    ></input>
                  </div>
                  <div
                    className="form-group"
                    style={{ textAlign: "left", marginBottom: "5%" }}
                  >
                    <label htmlFor="inputLine" style={{ color: "white" }}>
                      LINE
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputLine"
                      name="from_line"
                      aria-describedby="LINE Help"
                      placeholder=""
                      onChange={onTypeLine}
                      onClick={onTypeLine}
                    ></input>
                  </div>
                  <div
                    style={{
                      fontFamily: "PoppinsMedium",
                      fontSize: "10px",
                      textAlign: "center",
                      color: "white",
                      marginBottom: "5%",
                    }}
                  >
                    by registering you agree to the terms and conditions
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "5%",
                    }}
                  >
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_SITE_KEY}
                      ref={captchaRef}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{
                      width: "40%",
                      border: "1px solid white",
                      borderRadius: "0px",
                      backgroundColor: "rgb(0 0 0 / 0%)",
                    }}
                  >
                    SEND
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Element>
      <Element name="contract" className="row p-0 m-0 section4" section="4">
        <div className="section4_2">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              // width: "62%",
              // maxWidth: "445px",
            }}
          >
            <img
              className="section4_2_contractLogo"
              src={contractLogo}
              alt="Logo"
            />
          </div>
          {/* <div
            style={{
              fontFamily: "PoppinsMedium",
              fontSize: "36px",
              textAlign: "left",
              fontWeight: 600,
            }}
          >
            SEVEN BRIDGES COMPANY LIMITED
          </div> */}
          <div
            style={{
              fontFamily: "PoppinsMedium",
              fontSize: "17px",
              textAlign: "left",
              fontWeight: 600,
            }}
          >
            42/824 หมู่บ้าน เค.ซี.เลคพาราไดซ์ ซอยนิมิตใหม่ 40
            <br />
            แขวงสามวาตะวันออก เขตคลองสามวา กรุงเทพฯ 10510
          </div>
          <div className="section4_2_4">
            <div>
              <a
                href="tel:+66818395066"
                style={{
                  textDecoration: "none !important",
                  color: "inherit !important",
                }}
              >
                TEL : +66 81 839 5066
              </a>
            </div>
            <div>
              <a
                href="mailto:sevenbridgesdata@gmail.com"
                style={{
                  textDecoration: "none !important",
                  color: "inherit !important",
                }}
              >
                EMAIL : SEVENBRIDGESDATA@GMAIL.COM
              </a>
            </div>
            {/* <div>FACEBOOK : SEVEN BRIDGES</div> */}
            <div style={{ marginTop: "18px" }}>
              <a
                style={{ cursor: "pointer" }}
                href="https://www.facebook.com/profile.php?id=100063563948180"
                target="blank"
              >
                <img src={fb} alt="facebook icon"></img>
              </a>
              <a
                style={{ cursor: "pointer", marginLeft: "13px" }}
                href="https://www.instagram.com/7bsevenbridges?igsh=OTY1bXpjcmFydHN0"
                target="blank"
              >
                <img src={ig} alt="instagram icon"></img>
              </a>
            </div>
          </div>
        </div>
      </Element>
      <div className="row p-0 m-0 section5" section="5">
        <p
          className="text-center"
          style={{ paddingTop: "72px", fontSize: "12px" }}
        >
          copyright ©2022. supported by seven bridges creation
        </p>
      </div>
    </div>
  );
}
