import React from "react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import img1 from "../../Data/INOVE/INOVE Lo-res/DSCF2103.jpg";
import img2 from "../../Data/INOVE/INOVE Lo-res/DSCF2101.jpg";
import img3 from "../../Data/INOVE/INOVE Lo-res/DSCF2099.jpg";

const INOVEG1 = ({ setModalInove }) => {
  return (
    <>
      <MDBRow>
        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img1}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalInove(img1)}
          />
        </MDBCol>
        <MDBCol lg={4} md={12} className="mb-4 mb-lg-0">
          <img
            src={img2}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalInove(img2)}
          />
        </MDBCol>
        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img3}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalInove(img3)}
          />
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default INOVEG1;
