import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../Data/Asset 3-8.png";
import * as Scroll from "react-scroll";
import "./Navbar.css";

export default function Navbar() {
  const path = useLocation().pathname;
  const location = path.split("/")[1];
  const navigate = useNavigate();
  const scroller = Scroll.scroller;

  const goToHomeAndAbout = async () => {
    await closeMobile();
    await navigate("/");
    await scroller.scrollTo("about", {
      duration: 1000,
      delay: 0,
      smooth: true,
      offset: 0,
    });
  };

  const goToAbout = () => {
    scroller.scrollTo("about", {
      duration: 1000,
      delay: 0,
      smooth: true,
      offset: 0,
    });
  };

  const goToHomeAndContract = async () => {
    await closeMobile();
    await navigate("/");
    await scroller.scrollTo("contractEmail", {
      duration: 1000,
      delay: 0,
      smooth: true,
      offset: 0,
    });
  };

  const goToContract = () => {
    scroller.scrollTo("contractEmail", {
      duration: 1000,
      delay: 0,
      smooth: true,
      offset: 0,
    });
  };

  const closeMobile = () => {};

  return (
    <div className="nav-main">
      <div className="nav-logo">
        <div>
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>

        <div className="nav-group">
          <Link className="Link" to="/">
            HOME
          </Link>
          {location === "" ? (
            <div className="Link" onClick={goToAbout}>
              ABOUT
            </div>
          ) : (
            <div className="Link" onClick={goToHomeAndAbout}>
              ABOUT
            </div>
          )}

          <Link className="Link" to="/Project">
            PROJECTS
          </Link>
          {location === "" ? (
            <div className="Link" onClick={goToContract}>
              CONTACT
            </div>
          ) : (
            <div className="Link" onClick={goToHomeAndContract}>
              CONTACT
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
