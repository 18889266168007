import React from "react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import img2 from "../../Data/AR 2/PLAN/Aree Plan_1st Floor.jpg";
import img3 from "../../Data/AR 2/PLAN/Aree Plan_2nd Floor.jpg";
import img4 from "../../Data/AR 2/PLAN/Aree Plan_3rd Floor.jpg";
import img5 from "../../Data/AR 2/PLAN/Aree Plan_4th Floor.jpg";
import img6 from "../../Data/AR 2/PLAN/Aree Plan_Basement  Floor.jpg";

const AR2G3 = ({ setModalAR2G3 }) => {
  return (
    <>
      <MDBRow>
        <MDBCol lg={4} md={12} className="mb-4 mb-lg-0">
          <img
            src={img2}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G3(img2)}
          />

          <img
            src={img3}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G3(img3)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img4}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G3(img4)}
          />

          <img
            src={img5}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G3(img5)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img6}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G3(img6)}
          />
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default AR2G3;
