import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Card.css";

export default class Card extends Component {
  render() {
    return (
      <Link className="card-main" to={this.props.goto}>
        <img
          src={this.props.image}
          alt="Cover"
          className="card-image"
          style={this.props.style}
        />
        <div className="card-header">{this.props.header}</div>
        <div className="card-p1">{this.props.pa1}</div>
        <div className="card-p1">{this.props.pa2}</div>
      </Link>
    );
  }
}
