import React from "react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import img1 from "../../Data/JAYHOUSE/DSC_2795_resize.jpg";
import img2 from "../../Data/JAYHOUSE/DSC_2796_resize.jpg";
import img3 from "../../Data/JAYHOUSE/DSC_2991_resize.jpg";
import img4 from "../../Data/JAYHOUSE/DSC_2924_resize.jpg";
import img5 from "../../Data/JAYHOUSE/DSC_2848_resize.jpg";
import img6 from "../../Data/JAYHOUSE/DSC_2839_resize.jpg";
import img7 from "../../Data/JAYHOUSE/DSC_2877_resize.jpg";
import img8 from "../../Data/JAYHOUSE/DSC_2880_resize.jpg";
import img9 from "../../Data/JAYHOUSE/DSC_2902_resize.jpg";
import img10 from "../../Data/JAYHOUSE/DSC_2905_resize.jpg";
import img11 from "../../Data/JAYHOUSE/DSC_2855_resize.jpg";
import img12 from "../../Data/JAYHOUSE/DSC_2854_resize.jpg";

const JAYHOUSEG1 = ({ setModalJayHouse }) => {
  return (
    <>
      <MDBRow>
        <MDBCol lg={4} md={12} className="mb-4 mb-lg-0">
          <img
            src={img1}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalJayHouse(img1)}
          />

          <img
            src={img4}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalJayHouse(img4)}
          />

          <img
            src={img7}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalJayHouse(img7)}
          />

          <img
            src={img11}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalJayHouse(img11)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img2}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalJayHouse(img2)}
          />

          <img
            src={img5}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalJayHouse(img5)}
          />

          <img
            src={img8}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalJayHouse(img8)}
          />

          <img
            src={img10}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalJayHouse(img10)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img3}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalJayHouse(img3)}
          />

          <img
            src={img6}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalJayHouse(img6)}
          />

          <img
            src={img9}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalJayHouse(img9)}
          />

          <img
            src={img12}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalJayHouse(img12)}
          />
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default JAYHOUSEG1;
