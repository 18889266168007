// Modal.js

import React from "react";

const Modal = ({ isOpen, onClose, imageData }) => {
  if (!isOpen) return null;
  if (imageData === null || imageData === undefined) return null;

  return (
    <div
      onClick={onClose}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          background: "#dddddd",
          margin: "auto",
          padding: "5px",
          border: "2px solid rgb(0 0 0 / 0%)",
          borderRadius: "10px",
          boxShadow: "2px solid black",
        }}
      >
        <img
          src={imageData}
          className="shadow-1-strong rounded"
          style={{ maxHeight: "800px", maxWidth: "80vw" }}
          alt=""
        />
      </div>
    </div>
  );
};

export default Modal;
