import React, { useState } from "react";
import { slide as Menu } from "react-burger-menu";
// import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import * as Scroll from "react-scroll";
import "./HamburgerMenu.css";
import icon from "../../Data/list.svg";

export default function HamburgerMenu() {
  const path = useLocation().pathname;
  const location = path.split("/")[1];
  const navigate = useNavigate();
  const scroller = Scroll.scroller;
  const [menuOpenState, setMenuOpenState] = useState(false);

  const goToHomeAndAbout = async () => {
    closeSideBar();
    await closeMobile();
    await navigate("/");
    await scroller.scrollTo("about", {
      duration: 1000,
      delay: 0,
      smooth: true,
      offset: 0,
    });
  };

  const goToAbout = () => {
    closeSideBar();
    scroller.scrollTo("about", {
      duration: 1000,
      delay: 0,
      smooth: true,
      offset: 0,
    });
  };

  const goToHomeAndContract = async () => {
    closeSideBar();
    await closeMobile();
    await navigate("/");
    await scroller.scrollTo("contractEmail", {
      duration: 1000,
      delay: 0,
      smooth: true,
      offset: 0,
    });
  };

  const goToContract = () => {
    closeSideBar();
    scroller.scrollTo("contractEmail", {
      duration: 1000,
      delay: 0,
      smooth: true,
      offset: 0,
    });
  };

  const goToProject = async () => {
    closeSideBar();
    await navigate("/Project");
  };

  const goToHome = async () => {
    closeSideBar();
    await navigate("/");
  };

  const closeMobile = () => {};

  // const onClose = (value) => {
  //   if (!value) {
  //     console.log("Do only on true");
  //     setMenuOpenState(true);
  //   }
  // };

  const handleIsOpen = () => {
    setMenuOpenState(!menuOpenState);
  };

  const closeSideBar = () => {
    setMenuOpenState(false);
  };

  var styles = {
    bmBurgerButton: {
      position: "fixed",
      width: "36px",
      height: "30px",
      left: "25px",
      top: "25px",
    },
    bmBurgerBars: {
      background: "rgb(0 0 0 / 88%)",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
    },
    bmCross: {
      background: "#bdc3c7",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
    },
    bmMenu: {
      background: "rgb(0 0 0 / 88%)",
      padding: "2.5em 1.5em 0",
      fontSize: "1.15em",
    },
    bmMorphShape: {
      fill: "rgb(0 0 0 / 88%)",
    },
    bmItemList: {
      color: "#b8b7ad",
      padding: "0.8em",
      display: "flex",
      flexDirection: "column",
    },
    bmItem: {
      display: "inline-block",
      marginBottom: "10px",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
    },
  };

  return (
    <Menu
      styles={styles}
      customBurgerIcon={<img src={icon} alt="" />}
      isOpen={menuOpenState}
      onOpen={handleIsOpen}
      onClose={handleIsOpen}
    >
      {/* <Link className="Link" to="/">
        HOME
      </Link> */}
      <div className="Link" onClick={goToHome}>
        HOME
      </div>
      {location === "" ? (
        <div className="Link" onClick={goToAbout}>
          ABOUT
        </div>
      ) : (
        <div className="Link" onClick={goToHomeAndAbout}>
          ABOUT
        </div>
      )}
      <div className="Link" onClick={goToProject}>
        PROJECT
      </div>
      {/* <Link className="Link" to="/Project">
        PROJECTS
      </Link> */}
      {location === "" ? (
        <div className="Link" onClick={goToContract}>
          CONTACT
        </div>
      ) : (
        <div className="Link" onClick={goToHomeAndContract}>
          CONTACT
        </div>
      )}
    </Menu>
  );
}
