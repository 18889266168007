import React from "react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import img0 from "../../Data/SENA/0.jpg";
import img1 from "../../Data/SENA/1.jpg";
import img3 from "../../Data/SENA/3.jpg";
import img4 from "../../Data/SENA/4.jpg";
import img5 from "../../Data/SENA/5.jpg";
import img6 from "../../Data/SENA/6.jpg";
import img7 from "../../Data/SENA/7.jpg";
import img8 from "../../Data/SENA/8.jpg";
import img9 from "../../Data/SENA/9.jpg";
import img10 from "../../Data/SENA/10.jpg";
import img11 from "../../Data/SENA/11.jpg";
import img12 from "../../Data/SENA/12.jpg";
import img13 from "../../Data/SENA/13.jpg";
import img14 from "../../Data/SENA/14.jpg";
import img15 from "../../Data/SENA/15.JPG";
import img16 from "../../Data/SENA/16.JPG";
import img17 from "../../Data/SENA/17.JPG";
import img18 from "../../Data/SENA/18.JPG";

const SENAG1 = ({ setModalSena }) => {
  return (
    <>
      <MDBRow>
        <MDBCol lg={4} md={12} className="mb-4 mb-lg-0">
          <img
            src={img0}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalSena(img0)}
          />

          <img
            src={img4}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalSena(img4)}
          />

          <img
            src={img7}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalSena(img7)}
          />

          <img
            src={img10}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalSena(img10)}
          />

          <img
            src={img13}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalSena(img13)}
          />

          <img
            src={img16}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalSena(img16)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img1}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalSena(img1)}
          />
          <img
            src={img5}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalSena(img5)}
          />

          <img
            src={img8}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalSena(img8)}
          />

          <img
            src={img11}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalSena(img11)}
          />

          <img
            src={img14}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalSena(img14)}
          />

          <img
            src={img17}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalSena(img17)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img3}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalSena(img3)}
          />

          <img
            src={img6}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalSena(img6)}
          />

          <img
            src={img9}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalSena(img9)}
          />

          <img
            src={img12}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalSena(img12)}
          />

          <img
            src={img15}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalSena(img15)}
          />

          <img
            src={img18}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalSena(img18)}
          />
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default SENAG1;
