import React from "react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import img1 from "../../Data/AR 2/FAMILY Lo-res/DSC_0019.jpg";

import img2 from "../../Data/AR 2/FAMILY Lo-res/DSC_0020.jpg";
import img3 from "../../Data/AR 2/FAMILY Lo-res/DSC_0023.jpg";
import img4 from "../../Data/AR 2/FAMILY Lo-res/DSC_0026.jpg";
import img5 from "../../Data/AR 2/FAMILY Lo-res/DSC_0035.jpg";
import img6 from "../../Data/AR 2/FAMILY Lo-res/DSC_0047.jpg";
import img7 from "../../Data/AR 2/FAMILY Lo-res/DSC_0106.jpg";

import img8 from "../../Data/AR 2/FAMILY Lo-res/DSC_0109.jpg";
import img9 from "../../Data/AR 2/FAMILY Lo-res/DSC_0195.jpg";
import img10 from "../../Data/AR 2/FAMILY Lo-res/DSC_0198.jpg";
import img11 from "../../Data/AR 2/FAMILY Lo-res/DSC_0210.jpg";
import img12 from "../../Data/AR 2/FAMILY Lo-res/DSC_0229.jpg";
import img13 from "../../Data/AR 2/FAMILY Lo-res/DSC_0232.jpg";

const AR2G2 = ({ setModalAR2G2 }) => {
  return (
    <>
      <MDBRow>
        <MDBCol className="mb-4 mb-lg-0">
          <img
            src={img1}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            style={{ maxHeight: "650px", objectFit: "cover" }}
            onClick={() => setModalAR2G2(img1)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol lg={4} md={12} className="mb-4 mb-lg-0">
          <img
            src={img2}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G2(img2)}
          />

          <img
            src={img3}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G2(img3)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img4}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G2(img4)}
          />

          <img
            src={img5}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G2(img5)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img6}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G2(img6)}
          />

          <img
            src={img7}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G2(img7)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol lg={4} md={12} className="mb-4 mb-lg-0">
          <img
            src={img8}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G2(img8)}
          />

          <img
            src={img9}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G2(img9)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img10}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G2(img10)}
          />

          <img
            src={img11}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G2(img11)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img12}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G2(img12)}
          />

          <img
            src={img13}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalAR2G2(img13)}
          />
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default AR2G2;
