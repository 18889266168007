import React, { Component } from "react";
import Card from "../Card/Card";
import "./Project.css";

import img1 from "../../Data/AR 2/COMMON Lo-res/DJI_0433.jpg";
import img2 from "../../Data/N11/N11Lo-res/DSC_4071.jpg";
import img3 from "../../Data/VISANU/VISANU Lo-res/DSC_6149.jpg";
import img4 from "../../Data/DULIYA/DULIYA Lo-res/duliya02.jpg";
import img5 from "../../Data/PRIN/PRIN Lo-res/DSC_0050_2.jpg";
import img6 from "../../Data/IDEO/IDEO Lo-res/DSCF2343-re3_2.jpg";
import img7 from "../../Data/RAYA/RAYA Lo-res/raya-reno05re_2.jpg";
// import img8 from "../../Data/INOVE/INOVE Lo-res/DSCF2104-re.jpg";
import img9 from "../../Data/JAYHOUSE/DSC_2795_resize.jpg";
import img10 from "../../Data/SENA/0.jpg";

export default class Project extends Component {
  render() {
    return (
      <div className="container-fluid text-center p-0 m-0">
        <div className="row p-0 m-0 pro-section1"></div>
        <div className="p-5 mt-5 pro-section2">
          <div className="row">
            <div className="col-md">
              <Card
                image={img1}
                header="AR2 RESIDENCE"
                pa1="BANGKOK, THAILAND"
                pa2="2018"
                goto="AR2"
              />
            </div>
            <div className="col-md">
              <Card
                image={img2}
                header="N11 RESIDENCE"
                pa1="SAMUT PRAKAN, THAILAND"
                pa2="2022"
                goto="N11"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <Card
                image={img3}
                header="VISANU RESIDENCE"
                pa1="BANGKOK, THAILAND"
                pa2="2015"
                goto="VISANU"
              />
            </div>
            <div className="col-md">
              <Card
                image={img4}
                header="DULIYA RESIDENCE"
                pa1="BANGKOK, THAILAND"
                pa2="2002"
                goto="DULIYA"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <Card
                image={img5}
                style={{ filter: "brightness(140%)" }}
                header="PRIN RESIDENCE"
                pa1="BANGKOK, THAILAND"
                pa2="2012"
                goto="PRIN"
              />
            </div>
            <div className="col-md">
              <Card
                image={img6}
                header="IDEO RACHAPRAROP"
                pa1="BANGKOK, THAILAND"
                pa2="2009"
                goto="IDEO"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <Card
                image={img7}
                header="RAYAVADEE RENOVATION"
                pa1="KRABI, THAILAND"
                pa2="2005"
                goto="RAYAVADEE"
              />
            </div>
            <div className="col-md">
              <Card
                image={img9}
                header="JAY HOUSE"
                pa1="BANGKOK, THAILAND"
                pa2="2023"
                goto="JAYHOUSE"
              />
            </div>
            {/* <div className="col-md">
              <Card image={img8} header="INOVE" pa1="-" pa2="-" goto="INOVE" />
            </div> */}
          </div>
          <div className="row">
            <div className="col-md-6">
              <Card
                image={img10}
                header="SENERA SENIOR WELLNESS"
                pa1="BANGKOK, THAILAND"
                pa2="2023"
                goto="SENA"
              />
            </div>
          </div>
        </div>
        <div className="row p-0 m-0 section5" section="5">
          <p
            className="text-center"
            style={{ paddingTop: "72px", fontSize: "12px" }}
          >
            copyright ©2022. supported by seven bridges creation
          </p>
        </div>
      </div>
    );
  }
}
