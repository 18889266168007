import React from "react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import img2 from "../../Data/DULIYA/DULIYA Lo-res/duliya01.jpg";
import img3 from "../../Data/DULIYA/DULIYA Lo-res/duliya03.jpg";
import img4 from "../../Data/DULIYA/DULIYA Lo-res/duliya04.jpg";
import img5 from "../../Data/DULIYA/DULIYA Lo-res/duliya05.jpg";
import img6 from "../../Data/DULIYA/DULIYA Lo-res/duliya06.jpg";
import img7 from "../../Data/DULIYA/DULIYA Lo-res/duliya07.jpg";

const DULIYAG1 = ({ setModalDuliya }) => {
  return (
    <>
      <MDBRow>
        <MDBCol lg={4} md={12} className="mb-4 mb-lg-0">
          <img
            src={img2}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalDuliya(img2)}
          />

          <img
            src={img3}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalDuliya(img3)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img4}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalDuliya(img4)}
          />

          <img
            src={img5}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalDuliya(img5)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img6}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalDuliya(img6)}
          />

          <img
            src={img7}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalDuliya(img7)}
          />
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default DULIYAG1;
