import React from "react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import img1 from "../../Data/RAYA/RAYA Lo-res/raya-reno05re.jpg";
import img2 from "../../Data/RAYA/RAYA Lo-res/raya-reno06.jpg";
import img3 from "../../Data/RAYA/RAYA Lo-res/raya-reno07.jpg";
import img4 from "../../Data/RAYA/RAYA Lo-res/raya-reno08.jpg";
import img5 from "../../Data/RAYA/RAYA Lo-res/raya-spa-01.jpg";
import img6 from "../../Data/RAYA/RAYA Lo-res/raya-spa-02.jpg";
import img7 from "../../Data/RAYA/RAYA Lo-res/raya-spa-03.jpg";
import img8 from "../../Data/RAYA/RAYA Lo-res/raya-spa-04.jpg";
import img9 from "../../Data/RAYA/RAYA Lo-res/raya-spa-05.jpg";
import img10 from "../../Data/RAYA/RAYA Lo-res/raya-spa-06.jpg";

const RAYAG1 = ({ setModalRaya }) => {
  return (
    <>
      <MDBRow>
        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img3}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalRaya(img3)}
          />

          <img
            src={img4}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalRaya(img4)}
          />
        </MDBCol>
        <MDBCol lg={4} md={12} className="mb-4 mb-lg-0">
          <img
            src={img1}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalRaya(img1)}
          />

          <img
            src={img2}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalRaya(img2)}
          />
        </MDBCol>
        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img5}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalRaya(img5)}
          />

          <img
            src={img6}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalRaya(img6)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol lg={4} md={12} className="mb-4 mb-lg-0">
          <img
            src={img7}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalRaya(img7)}
          />
        </MDBCol>
        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img9}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalRaya(img9)}
          />
          <img
            src={img10}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalRaya(img10)}
          />
        </MDBCol>
        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img8}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalRaya(img8)}
          />
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default RAYAG1;
