import React from "react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import img2 from "../../Data/N11/Plan/Plan_Floor1.jpg";
import img3 from "../../Data/N11/Plan/Plan_Floor2.jpg";
import img4 from "../../Data/N11/Plan/Plan_Floor3.jpg";

const N11G3 = ({ setModalN11G2 }) => {
  return (
    <>
      <MDBRow>
        <MDBCol lg={4} md={12} className="mb-4 mb-lg-0">
          <img
            src={img2}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalN11G2(img2)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img3}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalN11G2(img3)}
          />
        </MDBCol>

        <MDBCol lg={4} className="mb-4 mb-lg-0">
          <img
            src={img4}
            className="w-100 shadow-1-strong rounded mb-4"
            alt=""
            onClick={() => setModalN11G2(img4)}
          />
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default N11G3;
