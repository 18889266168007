import Carousel from "react-bootstrap/Carousel";

function UncontrolledExample() {
  return (
    <Carousel>
      <Carousel.Item interval={3000}>
        <img
          className="section1"
          src="https://sevenbridges.co.th/Images/DJI_0001%20-%20Copy.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img
          className="section1"
          src="https://sevenbridges.co.th/Images/DSC_4071%20-%20Copy.jpg"
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img
          className="section1"
          src="https://sevenbridges.co.th/Images/DSC_4132%20-%20Copy.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img
          className="section1"
          src="https://sevenbridges.co.th/Images/DJI_0433%20-%20Copy.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img
          className="section1"
          src="https://sevenbridges.co.th/Images/DSC_2795_resize.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default UncontrolledExample;
